.footer-container {
    width: 80%;
    margin: 0 auto;
}
.m-d{
        background-image: linear-gradient(var(--dir),rgba(253,169,72,var(--alpha1)) var(--pos1),rgba(246,103,72,var(--alpha1)) var(--pos2),rgba(236,54,83,var(--alpha3)) var(--pos3),rgba(193,58,156,var(--alpha4)) var(--pos4));
        --pos1: 10%;
        --pos2: 20%;
        --pos3: 30%;
        --pos4: 65%;
    }

.ftr{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 1020px) and (min-width: 320px) {
    .footer-wrapper {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .footer-wrapper {
        align-items: center;
        justify-content: space-between;
        padding: 14px 0px 40px 0px!important;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.6rem;
        gap: 0.6rem;
    }
}
.footer-wrapper {
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0px 40px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
}
.contact-wrapper-inner img{
    width: 50px;
    margin-right: 7px;
}
.footer-copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ED1B24;
}

.foot-content {
    margin: 5px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    width: 100%;
    margin: 20px 0px;
}

.foot-details {
    display: flex;
}
.contact-wrapper-inner p a{
    color: #fff;
    font-size: 17px;
    font-weight: 500;
}
.foot-item {
    margin-left: 30px;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-title {
    font-size: 19px;
    font-weight: 800;
    align-self: stretch;
    margin-top: 0;
}

.address-wrapper {
    display: flex;
    align-items: center;
}

.contact-wrapper {
    display: flex;
    flex-direction: column;
}

.contact-wrapper-inner {
    display: flex;
    align-items: center;
}
.contact-wrapper-inner svg{
    font-size: 30px;
    margin-right: 10px;
}
.contact-wrapper-inner:hover .iconsmain{
    transform: scale(1.1);
    color: #f00;
}


.cont1 :hover{
    cursor: pointer;
    font-weight: 900;
    font-size: 19px;
    color:#ED1B24;
}
.md1 svg{
    font-size: 30px;
    border-radius: 20%;
}
.md2 svg{
   
    font-size: 30px;
    border-radius: 20%;
}
.md3 svg{
    font-size: 30px;
    border-radius: 20%;
}
.md4 svg{
    font-size: 30px;
    border-radius: 20%;
}
.md4 a{
    color:#fff;
}
.md1 a{
    color:#fff;
}
.md2 a{
    color:#fff;
}
.md3 a{
    color:#fff;
}



.foot-item p {
    margin: 7px 0;
    
}
.button.extended-background-gradient-linkedin, .linkedin-branded-icon::before, button.extended-background-gradient-linkedin {
    background-image: linear-gradient(var(--dir),rgba(10,102,194,var(--alpha1)) var(--pos1),rgba(27,128,205,var(--alpha2)) var(--pos2),rgba(100,166,216,var(--alpha3)) var(--pos3));
}
.button[class*=extended-background-gradient] {
    background-image: linear-gradient(var(--dir),rgba(var(--gradient-color),var(--alpha1)) var(--pos1),rgba(var(--gradient-color-tint),var(--alpha2)) var(--pos2),rgba(var(--gradient-color-hover),var(--alpha3)) var(--pos4));
    background-size: 200% auto;
    background-position: left;
    border: 0;
    position: relative;
}
.foot-item-img {
    height: 25px;
    margin-right: 15px;
}

.footer-copyright-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-img {
    height: 90px
}

@media only screen and (max-width: 720px) {
    .footer-wrapper {
        flex-direction: column;
    }
    .footer-container {
        width: 90%;
        margin: 0 auto;
    }
    .foot-details {
        flex-direction: column;
        align-items: stretch;
    }
    .foot-item {
        margin: 10px 0;
        align-items: stretch;
        margin-bottom: 35px;

    }
}

@media only screen and (min-width: 720px) and (max-width: 992px) {
    .footer-container {
        width: 90%;
        margin: 0 auto;
    }
    .foot-details {
        align-items: stretch;
        justify-content: space-around;
        align-self: stretch;
    }
    .foot-item {
        margin: 10px 0;
        align-items: stretch;
        margin-bottom: 35px;
    }
    .footer-wrapper {
        flex-direction: column;
    }
    
}

.fotterh{
 display: flex;
 align-items: center;
 cursor: pointer;
    transition: transform 0.3s ease;
}
.icon, .phone-number {
    transition: transform 0.3s ease, color 0.3s ease;
}

.fotterh:hover {
    transform: scale(1.1);
}

.fotterh:hover .icon {
    /* transform: rotate(360deg); */
    color: #ED1B24;
}

.fotterh:hover .phone-number {
    transform: translateX(10px);
    color: #ED1B24;
}




